import React from 'react';
// import {
//     ResponsiveContainer, LineChart, Line, CartesianGrid, Legend, ErrorBar, AreaChart, Area,
//     Label, LabelList, BarChart, Bar, Brush, Cell, ReferenceLine, ReferenceDot,
//     XAxis, YAxis, Tooltip
// } from 'recharts';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Label, ResponsiveContainer } from 'recharts';

import classes from './CompanyGrowth.module.scss';

const CompanyGrowth = props => {

    if (!props.active) {
        return null;
    }

    // We can do a, b or we can just have years, or we can just do '15 | '16

    const data1 = [
        { name: '2015 (Jan-Jun)', Dollar: 0.001, pv: 0, amt: 0 },
        { name: '2015 (Jul-Dec)', Dollar: 0.0125, pv: 0, amt: 0 },
        { name: '2016 (Jan-Jun)', Dollar: 0.25, pv: 0, amt: 0 },
        { name: '2016 (Jul-Dec)', Dollar: 0.25, pv: 0, amt: 0 },
        { name: '2017 (Jan-Jun)', Dollar: 0.5, pv: 0, amt: 0 },
        { name: '2017 (Jul-Dec)', Dollar: 1, pv: 0, amt: 0 },
        { name: '2018 (Jan-Jun)', Dollar: 5, pv: 0, amt: 0 },
        { name: '2018 (Jul-Dec)', Dollar: 5, pv: 0, amt: 0 },
        { name: '2019 (Jan-Jun)', Dollar: 10, pv: 0, amt: 0 },
        { name: '2019 (Jul-Dec)', Dollar: 20, pv: 0, amt: 0 },
        { name: '2020 (Jan-Jun)', Dollar: 20, pv: 0, amt: 0 },
        { name: '2020 (Jul-Dec)', Dollar: 20, pv: 0, amt: 0 },
        { name: '2021 (Jan-Jun)', Dollar: 20, pv: 0, amt: 0 },
        { name: '2021 (Jul-Dec)', Dollar: 22.50, pv: 0, amt: 0 },
        { name: '2022 (Jan-Jun)', Dollar: 22.50, pv: 0, amt: 0 },
        { name: '2022 (Jul-Dec)', Dollar: 22.50, pv: 0, amt: 0 },
        { name: '2023 (Jan-Jun)', Dollar: 45.00, pv: 0, amt: 0 },
    ]; 


    // const data2 = [
    //     { name: '2015 (Jan-Jun)', Dollar: 0.001, pv: 0, amt: 0 },
    //     { name: '2015 (Jul-Dec)', Dollar: 0.0125, pv: 0, amt: 0 },
    //     { name: '2016 (Jan-Jun)', Dollar: 0.25, pv: 0, amt: 0 },
    //     { name: '2016 (Jul-Dec)', Dollar: 0.25, pv: 0, amt: 0 },
    //     { name: '2017 (Jan-Jun)', Dollar: 0.5, pv: 0, amt: 0 },
    //     { name: '2017 (Jul-Dec)', Dollar: 1, pv: 0, amt: 0 },
    //     { name: '2018 (Jan-Jun)', Dollar: 5, pv: 0, amt: 0 },
    //     { name: '2018 (Jul-Dec)', Dollar: 5, pv: 0, amt: 0 },
    //     { name: '2019 (Jan-Jun)', Dollar: 10, pv: 0, amt: 0 },
    //     { name: '2019 (Jul-Dec)', Dollar: 20, pv: 0, amt: 0 },
    //     { name: '2020 (Jan-Jun)', Dollar: 20, pv: 0, amt: 0 },
    //     { name: '2020 (Jul-Dec)', Dollar: 20, pv: 0, amt: 0 },
    //     { name: '2021 (Jan-Jun)', Dollar: 20, pv: 0, amt: 0 },
    //     { name: '2021 (Jul-Oct)', Dollar: 22.50, pv: 0, amt: 0 },
    //     { name: '2021 (Nov-Dec)', Dollar: 70, pv: 0, amt: 0 },
    //     { name: '2022 (Jan-Jun)', Dollar: 70, pv: 0, amt: 0 },
    // ];

    return (
        <>
            <div className={classes.companyGrowth}>

                <h4>2015-2022 STOCK CHART HISTORY</h4>

                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={data1} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                        <Line 
                            type="monotone" 
                            dataKey="Dollar" 
                            stroke="#8884d8" 
                            strokeWidth="2" 
                            dot={{ stroke: 'red', strokeWidth: 2 }}
                            activeDot={{ stroke: 'red', strokeWidth: 2, r: 10 }}
                        />
                        
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

                        <XAxis 
                            dataKey="name" 
                            height={50} 
                            allowDataOverflow="true"
                        >
                            <Label value="Year" position="insideBottom" />
                        </XAxis>
                        
                        <YAxis 
                            type="number"
                            width={70}
                        >
                            <Label value="Stock Value ($)" angle={-90} />
                        </YAxis>

                        <Tooltip />

                    </LineChart>
                </ResponsiveContainer>
    
            </div>

            {/* <div className={classes.companyGrowth}>

                <h4>2022 ESTIMATED STOCK CHART</h4>

                <ResponsiveContainer width="100%" height="100%">
                    <LineChart width={900} height={300} data={data2} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                        <Line type="monotone" dataKey="Dollar" stroke="#8884d8" />
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

                        <XAxis dataKey="name" height={50} >
                            <Label value="Year" position="insideBottom" />
                        </XAxis>
                        <YAxis type="number">
                            <Label value="Stock Value ($)" angle={-90} />
                        </YAxis>

                        <Tooltip />

                    </LineChart>
                </ResponsiveContainer>
       
            </div> */}
        </>
    );

}

export default CompanyGrowth;