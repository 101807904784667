import React, { useRef, useState, useEffect } from 'react';
import './ImageUpload.scss';
import Button from './Button';

const ImageUpload = props => {

    const [file, setFile] = useState();
    const [previewUrl, setPreviewUrl] = useState();
    const [isValid, setIsValid] = useState(false);

    const filePickerRef = useRef();

    useEffect(() => {
        if (!file) {
            return;
        }

        const fileReader = new FileReader();
        fileReader.onload = () => {
            setPreviewUrl(fileReader.result);
        }
        fileReader.readAsDataURL(file);
    }, [file])

    const pickedHandler = event => {
        let pickedFile;
        let fileIsValid = isValid;
        if (event.target.files || event.target.files.length === 1) {
            pickedFile = event.target.files[0];
            setFile(pickedFile);
            setIsValid(true);
            fileIsValid = true;
        } else {
            setIsValid(false);
            fileIsValid = false;
        }

        props.onInput(props.id, pickedFile, fileIsValid);
    }

    const pickImageHandler = () => {
        filePickerRef.current.click();
    }

    return (
        <div className={`form-control ${props.inline && 'inline-block'}`}>
            <input
                id={props.id}
                ref={filePickerRef}
                style={{ display: 'none' }}
                type="file"
                accept=".jpg,.png,.jpeg"
                onChange={pickedHandler}
                name={props.id}
            />
            <div className={`image-upload ${props.center && 'center'}`}>
                {props.currentImg && !previewUrl && props.currentImg && (<div className="image-upload__preview">
                    <img src={`https://otherfoneapps.blob.core.windows.net/invest/public/img/users/${props.currentImg}`} alt="Current Img" />
                </div>)}
                {previewUrl && (<div className="image-upload__preview">
                    <img src={previewUrl} alt="Preview" />
                </div>)}
                <Button type="button" onClick={pickImageHandler}>{props.text}</Button>
            </div>
            {!isValid && <p>{props.errorText}</p>}

        </div>
    );
}

export default ImageUpload;